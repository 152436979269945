import './App.css';
import { GoldContent } from './components/gold-content'

function App() {
  return (
    <div className="App">
      <h1>It's Five <img className="logo" src="/logo512.png" alt="0"/> Clock Somewhere</h1>
      <GoldContent useCurrent={true} />
    </div>
  );
}

export default App;
