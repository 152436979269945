import houseJson from '../../data/house-price-uk.json'
import goldJson from '../../data/gold.json'
import fxJson from '../../data/exchange.json'
import salaryJson from '../../data/salary-uk.json'
import { Chart } from './line-chart'
import { Line } from 'recharts'
import React from 'react'
import { getYear } from '../../data/util'

const priceBlock = 1000
const roundToXdp = (x, n) => Math.round(n * (10 ** x)) / (10 ** x)
const yLabel = `Au(oz) or £(${priceBlock})`

const prepareData = (json, startYear, endYear, latest) => {
  goldJson[getYear()] = latest
  const { GBP } = fxJson
  return Object
    .keys(json)
    .filter(x => Number.parseInt(x) >= startYear && x <= endYear)
    .map((y) => {
       return {
         year: y,
         price: roundToXdp(2, json[y] / priceBlock),
         gold: roundToXdp(1, json[y] * GBP[y] / goldJson[y] * 100)
      }
    })
}

const DualChart = ({ data, lineLabel, yLabel, dk1, dk2, lineLabel2, title }) => {
  const first = data[0]
  const last = data[data.length - 1]
  const averageOunces = (data.map(d => d.gold).reduce((a, b) => a + b) / data.length).toFixed(2)

  return <>
    <Chart
      data={data}
      xDataKey="year"
      dataKey={dk1}
      height={400}
      lineLabel={lineLabel}
      yLabel={yLabel}
    >
      <Line
        dot={false}
        type="monotone"
        dataKey={dk2}
        stroke="#000099"
        name={lineLabel2}
        activeDot={{ r: 8 }}
      />
    </Chart>
    <p>
      Average {title} in {first.year} £{first.price * 1000} or {first.gold}oz of gold<br/>
      Average {title} in {last.year} £{last.price * 1000} or {last.gold}oz of gold<br/>
      Average {title} in ounces of gold between {first.year} and {last.year}: {averageOunces}
    </p>
  </>
}

export const HouseChart = ({ startYear, endYear, latest }) => <DualChart
  dk1="gold"
  data={prepareData(houseJson, startYear, endYear, latest)}
  lineLabel="houses in gold(oz)"
  lineLabel2={`houses in GBP(${priceBlock})`}
  yLabel={yLabel}
  dk2="price"
  title="UK house price"
/>

export const SalaryChart = ({ startYear, endYear, latest }) => <DualChart
  dk1="gold"
  data={prepareData(salaryJson, startYear, endYear, latest)}
  lineLabel="salaries in gold(oz)"
  lineLabel2={`salaries in GBP(${priceBlock})`}
  yLabel={yLabel}
  dk2="price"
  title="UK salary"
/>
